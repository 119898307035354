import store from "@/store";
function isStudent() {
    if (store.state.auth.jwtObject) { return store.state.auth.jwtObject.roleType == 1 } else {
        return false
    }
}

export function getUserData() {
    if (isStudent()) {
        return requestPc.v1.get("student/data")
    } else {
        return requestPc.v1.get("teacher/data")
    }
}

//用户可见资源等级
export function getVisibleLevels() {
    if (isStudent()) {
        return requestPc.v2.get("student/visible-levels");
    } else {
        return requestPc.v2.get("teacher/visible-levels");
    }
}

//推荐书籍 搜索 level书列表
export function getPCBookList(page, limit, level) {
    if (isStudent()) {
        return requestPc.v2.get("/student/books", { params: { page, limit, level } });
    } else {
        return requestPc.v2.get("/teacher/books", { params: { page, limit, level } });
    }
}

export function getBookSearchList(page, size, query) {
    return requestPc.v1.get("/book/search", { params: { page, size, query } });
}

//获取书籍信息
export function getBookDetail(bookId) {
    if (isStudent()) {
        return requestPc.v2.get(
            `student/books/${bookId}`
        );
    } else {
        return requestPc.v2.get(
            `teacher/books/${bookId}`
        );
    }
}

//获取书籍详情当前在读学生信息
export function getBookDetailRecentReadStudent(bookId) {
    if (isStudent()) {
        return requestPc.v2.get(
            `student/book/${bookId}/recent-read`
        );
    } else {
        return requestPc.v2.get(
            `teacher/book/${bookId}/recent-read`
        );
    }
}

//获取书籍详情阅读状态
export function getBookDetailReadState(bookId) {
    if (isStudent()) {
        return requestPc.v2.get(
            `student/book/${bookId}/read-infos`
        );
    } else {
        return requestPc.v2.get(
            `teacher/book/${bookId}/read-infos`
        );
    }
}

//获取书籍详情推荐书籍
export function getBookDetailRecommend(bookId) {
    if (isStudent()) {
        return requestPc.v2.get(
            `student/book/${bookId}/recommend-books`
        );
    } else {
        return requestPc.v2.get(
            `teacher/book/${bookId}/recommend-books`
        );
    }
}

//获取书籍详情读后感
export function getBookDetailAfterReading(schoolId, bookId, page, limit) {
    if (isStudent()) {
        return requestPc.v2.get(
            `student/school/${schoolId}/book-reviews/${bookId}`,
            {
                params: {
                    page,
                    limit,
                },
            }
        );
    } else {
        return requestPc.v2.get(
            `teacher/school/${schoolId}/book-reviews/${bookId}`,
            {
                params: {
                    page,
                    limit,
                },
            }
        );
    }
}

//书籍详情点赞
export function postBookDetailAfterReadingLike(noteId) {
    if (isStudent()) {
        return requestPc.v2.post("student/book-note/" + noteId + "/praise");
    } else {
        return requestPc.v2.post("teacher/book-note/" + noteId + "/praise");
    }
}

export function modifyPassword(password, passwordRepeat, verifyCode) {
    return requestPc.v2.post("teacher/password", { password, passwordRepeat, verifyCode });
}