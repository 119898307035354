import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./router-guard.js";
import store from "@/store";
import request from "./request";
import requestPc from "./request-pc";
import vuetify from "@/plugins/vuetify";
import "@/assets/iconfont/style.css";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import {
  resHost,
  apiHost,
  sentryEnable,
  wxHost,
  appHost,
  frontHost,
} from "./settings";
import { mapMutations, mapState } from "vuex";
import VuePageTitle from "vue-page-title";
import axios from "axios";
import VueAxios from "vue-axios";
import native from "./native";
import InfiniteLoading from "vue-infinite-loading";
import CInfiniteSnipper from "@/components/c-infinite-snipper";
// import "@/mock";
import "@/directives/inview.js";
import _ from "lodash";
import Track from "../../plugins/track";
Vue.prototype._ = _;
//请求对象
window.request = request;
window.requestPc = requestPc;

//标题栏设置
Vue.use(VuePageTitle, {
  prefix: "弘衍阅读",
  router,
});

//sentry监控
if (sentryEnable) {
  const appVersion = require("@/../package.json").version;
  const env = parseInt(process.env.VUE_APP_STAGING) ? "staging" : "production";
  Sentry.init({
    dsn: "https://56fbb4843c2e41429f4bf88d28ce495e@sentry.seedu.me/7",
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    release: `v${appVersion}-${env}`,
  });
}

Vue.mixin({
  data() {
    return {
      resHost,
      apiHost,
      wxHost,
      appHost,
      frontHost,
    };
  },
  methods: {
    ...mapMutations({
      showSnackbar: "utilsSnackbar/open",
      showDialog: "utilsDialog/open",
      closeDialog: "utilsDialog/close",
      closeSnackbar: "utilsSnackbar/close",
      showLoading: "utilsLoading/open",
      closeLoading: "utilsLoading/close",
    }),
  },
  computed: {
    ...mapState({
      native: (state) => state.native,
    }),
  },
});

Vue.use(Track, {
  showLog: true,
  postToServer: true,
  trackUrl: process.env.VUE_APP_TRACK_URL,
});//埋点相关
Vue.track.config(
  {
    type: "guest",
  },
  {
    name: "弘衍阅读",
  }
);//埋点相关
Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.use(native); //照片预览

Vue.use(InfiniteLoading, {
  slots: {
    spinner: CInfiniteSnipper,
  },
});

Vue.use(require("vue-moment"));

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");