import {
  genEvent,
  genAppInfo,
  genUserInfo,
  genDeviceInfo,
  genSessionInfo,
} from "./track.js";
import { genUUID, log, post } from "./utils";
import store from "@/store";
import MobileDetect from "mobile-detect";
export default {
  /**
   *
   * @param {object} Vue
   * @param {string} param1.showLog  日志状态
   * @param {string} param1.postToServer 抛送到服务器
   * @param {string} param1.trackUrl 埋点服务器地址
   */
  install: function (
    Vue,
    {
      log: showLog = "true",
      post: postToServer = "true",
      trackUrl: url = "",
    } = {}
  ) {
    //日志数据
    const trackDefaultData = {};
    //附加设备信息
    Object.assign(trackDefaultData, genDeviceInfo());
    //埋点接口
    const track = {
      /**
       * 埋点配置
       * @param {object} user 用户信息
       * @param {object} app app信息,
       * @param {string} associate_session_id 关联ID
       */
      config(user = null, app = null, associate_session_id = "") {
        //附加用户信息
        if (user) {
          const { type = "" } = user;
          Object.assign(trackDefaultData, genUserInfo(type));
        }
        //附加session信息
        Object.assign(trackDefaultData, genSessionInfo(associate_session_id));
        //附加app信息
        if (app) {
          const {
            name = "",
            channel = "browser",
            version = "",
            build = "",
            env = process.env.NODE_ENV,
          } = app;
          Object.assign(
            trackDefaultData,
            genAppInfo(name, channel, version, build, env)
          );
        }
      },
      /**
       * 埋点方法
       * @param {string} param0.category
       * @param {string} param0.name
       * @param {string} param0.value
       * @param {object} param0.params
       */
      async event({ category, name, value, params = {}, needJwt = true }) {
        const trackData = Object.assign(
          {},
          trackDefaultData,
          genEvent(category, name, value, JSON.stringify(params)),
          { id: genUUID() }
        );

        if (needJwt) {
          Object.assign(trackData, genUserInfo(store.getters["auth/isTeacher"] ? "teacher" : "student"));//这里加入用户类型
        }

        const os = new MobileDetect(window.navigator.userAgent).os();

        if (os == "iOS" || os == "AndroidOS") {
          Vue.native.track(
            {
              category: category,
              name: name,
              value: value,
              params: params
            }
          )
        } else {
          if (showLog == "true") log(trackData);
          if (postToServer == "true") post(url, trackData);
        }
      },
    };

    Vue.track = track;
    Vue.prototype.$track = track;
  },
};