<template>
  <c-snackbar @input="changeHandler" v-bind="snackbar"></c-snackbar>
</template>

<script>
import CSnackbar from "@/components/c-snackbar";
import { mapState, mapMutations } from "vuex";

export default {
  name: "global-snackbar",
  computed: {
    ...mapState({
      snackbar: v => v.utilsSnackbar
    })
  },
  methods: {
    ...mapMutations({
      closeSnackbar: "utilsSnackbar/close"
    }),
    changeHandler() {
      this.closeSnackbar();
    }
  },
  components: {
    CSnackbar
  }
};
</script>