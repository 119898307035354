<template>
  <c-loading-circle bg-color="transparent" snipper-color="#4d9f7c"></c-loading-circle>
</template>

<script>
import CLoadingCircle from "./c-loading-circle";
export default {
  name: "c-infinite-snipper",
  components: {
    CLoadingCircle,
  },
};
</script>