<template>
  <v-app>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath">
        <!-- 这里是会被缓存的视图组件，比如 Home！ -->
      </router-view>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>
    <global-popups></global-popups>
  </v-app>
</template>

<script>
import GlobalPopups from "./global-popups";
export default {
  name: "App",
  components: {
    GlobalPopups,
  },
};
</script>

<style lang="scss">
@import url("~@/assets/variables.scss");

html,
body,
#app {
  height: 100vh;
}
</style>
