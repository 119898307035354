export default{
    namespaced: true,
    state: {
        data: null
    },
    mutations: {
        set(state, data){
            if(typeof data == 'object'){
                state.data = Object.assign({}, data)
            }else{
                state.data = data
            }
        },
        clear(state){
            state.data = null;
        }
    },
    actions: {
        get({commit, state}){
            let { data } = state

            commit('clear')

            return data
        }
    },
}