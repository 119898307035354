import Vue from "vue";
import router from "./router";
import store from "@/store";

/**
 * 获取用户认证
 * @param {Router} to
 * @void
 */
function userAuth(to) {
  return new Promise((resolve) => {
    if (to.meta && to.meta.requireAuth) {
      if (store.getters["auth/valid"]) {
        resolve(true);
      } else {
        let jwt = window.localStorage.getItem("jwt")
        if (jwt) {
          store.commit("auth/set", jwt);
          resolve(true);
        } else {
          if (to.meta.isPC) {
            resolve(true);
          } else {
            Vue.native.getToken().then((jwt) => {
              store.commit("auth/set", jwt);
              resolve(true);
            });
          }
        }
      }
    } else {
      //不需要用户认证
      resolve(false);
    }
  });
}

/**
 * 获取系统状态栏高度
 */
function getStatusBarHeight(to) {
  return new Promise((resolve) => {
    if (to.meta && to.meta.requireStatusBarHeight) {
      if (store.state.native.statusBarHeight) {
        resolve(true);
      } else {
        setTimeout(function () {
          Vue.native.getStatusBarHeight().then((height) => {
            store.commit("native/setStatusBarHeight", height);

            resolve(true);
          });
        }, 500);
      }
    } else {
      resolve(false);
    }
  });
}

router.beforeEach(function (to, from, next) {
  const promises = [userAuth(to), getStatusBarHeight(to)];

  //执行所有promise
  Promise.all(promises).then(() => {
    next();
  });
});
