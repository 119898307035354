import Vue from "vue";
import enterView from "enter-view";

//进入可视范围
Vue.directive("inview", {
  inserted(el, binding) {
    const { id } = el;

    const {
      value: { enter = new Function(), exit = new Function() },
      //如果once开启exit不会触发
      modifiers: { once = false },
    } = binding;

    enterView({
      selector: `#${id}`,
      exit,
      enter,
      once,
    });
  },
});

// //退出可视范围
// Vue.directive("inview-exit", {
//   inserted(el, binding) {
//     const { id } = el;
//     const {
//       value: cbk,
//       modifiers: { once = false },
//     } = binding;

//     bindInviewEvent(id, "exit", cbk, once);
//   },
// });

// /**
//  * 事件注册
//  * @param {String} id
//  * @param {String} event
//  * @param {Function} cbk
//  * @param {Boolean} once
//  */
// function bindInviewEvent(id, event, cbk, once) {
//   if (event == "enter") {
//     enterView({
//       selector: `#${id}`,
//       enter: cbk,
//       once,
//     });
//     // inView(`#${id}`).once(event, cbk);
//   } else {
//     enterView({
//       selector: `#${id}`,
//       exit: cbk,
//       once,
//     });
//     // inView(`#${id}`).on(event, cbk);
//   }
// }
