import MobileDetect from "mobile-detect";
import { genUUID } from "./utils";

//生成SessionId
function genSessionInfo(associate_session_id = "") {
  return {
    session_info: {
      session_id: genUUID(),
      associate_session_id,
    },
  };
}

//生成
function genDeviceInfo() {
  const md = new MobileDetect(window.navigator.userAgent);
  const uuid = getDeviceId();
  const model = md.mobile();
  const os_version = md.versionStr("Build");
  const os = md.os();
  const type = md.tablet();
  //兼容IOS和火狐
  const network_type = navigator.connection
    ? navigator.connection.effectiveType
    : "";

  const language = navigator.language || navigator.userLanguage;

  return {
    device_info: { uuid, model, os_version, os, type, network_type, language },
  };
}

function getDeviceId() {
  let deviceUUID = localStorage.getItem("device_uuid");
  if (deviceUUID == null) {
    deviceUUID = genUUID();
    localStorage.setItem("device_uuid", deviceUUID);
  }
  return deviceUUID;
}

function genUserInfo(type) {
  let userId = localStorage.getItem("user_id");
  if (userId == null) {
    userId = genUUID();
    localStorage.setItem("user_id", userId);
  }

  return {
    user_info: {
      type,
      id: userId,
    },
  };
}

/**
 * 生成APP信息
 * @param {*} name 名字
 * @param {*} channel browser|app
 * @param {*} version 版本
 * @param {*} build  版本
 * @param {*} env 环境
 */
function genAppInfo(
  name = "",
  channel = "browser",
  version = "",
  build = "",
  env = "debug"
) {
  return {
    app_info: {
      token: "",
      name,
      channel,
      version,
      build,
      env,
    },
  };
}

/**
 * 生成事件
 * @param {string} category
 * @param {string} name
 * @param {string} value
 * @param {string} params
 */
function genEvent(category, name, value, params) {
  return {
    event: {
      timestamp: new Date().getTime(),
      category,
      name,
      value,
      params,
    },
  };
}

export { genEvent, genAppInfo, genUserInfo, genDeviceInfo, genSessionInfo };