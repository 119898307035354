import jwt_decode from "jwt-decode";

export default {
  namespaced: true,
  state: {
    //jwt字符串
    //学生
    jwt: "",
    //jwt对象
    jwtObject: null,
  },
  mutations: {
    //设置
    set(state, jwt) {
      state.jwt = jwt;
      try {
        state.jwtObject = jwt_decode(jwt);
      } catch (e) {
        console.error(e);
      }
    },
    //清除
    clear(state) {
      state.jwt = "";
      state.jwtObject = null;
    },
  },
  getters: {
    isTeacher: ({ jwtObject, jwt }) => {
      //兼容jwtObject为空状况
      jwtObject = jwtObject || jwt_decode(jwt);
      return jwtObject.roleType == 1 ? false : true;
    },
    //jwt有效性
    valid({ jwt }) {
      //验证
      return jwt ? true : false;
    },
    userId: ({ jwtObject, jwt }) => {
      //兼容jwtObject为空状况
      jwtObject = jwtObject || jwt_decode(jwt);
      return jwtObject.uid;
    },
  },
};