import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth.js";
import native from "./modules/native.js";
import utilsDialog from "./modules/utils/dialog";
import utilsSnackbar from "./modules/utils/snackbar";
import utilsLoading from "./modules/utils/loading";
import param from "./modules/param.js";
import teacherClass from "./modules/teacher-class";
import teacherInfo from "./modules/teacher-info";
import userInfo from "./modules/user-info";

const debug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    native,
    utilsDialog,
    utilsSnackbar,
    utilsLoading,
    param,
    teacherClass,
    teacherInfo,
    userInfo
  },
  strict: debug,
});
