<template>
  <v-dialog
    overlay-opacity="0.1"
    v-model="inputVal"
    :transition="false"
    :persistent="true"
    width="327"
    height="212"
  >
    <v-card class="d-flex" width="327" height="212">
      <v-col class="col" align-self="center">
        <v-row justify="center" class="mb-2 pa-0">
          <v-icon v-if="!isText" :color="iconColor" size="60">{{
            iconName
          }}</v-icon>
          <p
            v-if="isText"
            class="common--text text-center font-weight-bold mt-4 mb-0"
          >
            提示
          </p>
        </v-row>
        <v-row
          justify="center"
          class="common--text text-center subtitle-2"
          :class="isText ? 'font-weight-regular' : 'font-weight-bold'"
          >{{ text }}</v-row
        >
        <v-row justify="center" :class="isText ? 'pt-5' : ''">
          <v-btn
            v-for="({ cbk, text = '', color = 'minor' }, index) in btns"
            :key="index"
            @click="cbk"
            class="ma-4 mb-0"
            :width="btns.length == 1 ? 140 : 100"
            height="36"
            rounded
            outlined
            :color="color"
            >{{ text }}</v-btn
          >
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "c-msg-box",
  inheritAttrs: false,
  props: {
    //可见性
    value: {
      type: Boolean,
      default: false,
    },
    //图标"success", "info", "error", "text"
    icon: {
      type: String,
      default: "success",
      validator(value) {
        const iconTypes = ["success", "info", "error", "text"];
        return iconTypes.includes(value);
      },
    },
    text: {
      type: String,
      default: "",
    },
    btns: {
      type: Array,
      default: () => [
        {
          text: "确定",
          cbk: () => {},
          color: "minor",
        },
      ],
      validator({ length }) {
        return length > 0 && length <= 2;
      },
    },
  },
  data() {
    return {
      icons: {
        success: {
          name: "icon-success",
          color: "#4D9F7C",
        },
        info: {
          name: "icon-fail",
          color: "#c42d2d",
        },
        error: {
          name: "icon-close2",
          color: "#c42d2d",
        },
      },
    };
  },
  computed: {
    inputVal: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    iconName() {
      return this.icons[this.icon]["name"];
    },
    iconColor() {
      return this.icons[this.icon]["color"];
    },
    isText() {
      return this.icon == "text";
    },
  },
};
</script>
