<template>
  <c-msg-box v-bind="dialog" :value="visible" :btns="btns"></c-msg-box>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import CMsgBox from "@/components/c-msg-box";

export default {
  name: "global-msg-box",
  computed: {
    ...mapState({
      dialog: v => v.utilsDialog
    }),
    visible() {
      return this.dialog.visible;
    },
    btns() {
      const {
        dialog: { btns },
        closeDialog
      } = this;

      //如果未设置任何按钮，设置一个确定按钮
      if ((btns?.length ?? 0) == 0) {
        return [
          {
            text: "确定",
            cbk: closeDialog
          }
        ];
      } else {
        return btns;
      }
    }
  },
  methods: {
    ...mapMutations({
      closeDialog: "utilsDialog/close"
    })
  },
  components: {
    CMsgBox
  }
};
</script>