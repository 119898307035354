<template>
  <v-snackbar
    class="snackbar"
    :value="visible"
    v-on="$listeners"
    :timeout="3000"
    :translate="false"
    :centered="true"
    :absolute="true"
  >
    <pre>{{ msg }}</pre>
  </v-snackbar>
</template>

<script>
export default {
  name: "c-snackbar",
  inheritAttrs: false,
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: 3000,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/color";
.snackbar {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 14px;
  margin: 0 auto;
  max-width: 50%;
  min-width: 20%;
  z-index: 9999;

  ::v-deep .v-snack__wrapper {
    background: rgba($color: #000000, $alpha: 0.75);
  }

  ::v-deep .v-snack__content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 48px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    overflow: hidden;
    padding: 8px 16px;
    width: 100%;
    justify-content: center;
    @include fontStyle;
  }
}
</style>
