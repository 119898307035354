import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "pc-login",
      component: () => import("@/views/pc/login/pc-login.vue"),
    },
    //网页登录
    {
      path: "/pc-login",
      name: "pcLogin",
      component: () =>
        import("@/views/pc/login/pc-login.vue"),
    },
    //网页-学生登录
    {
      path: "/pc-login-student",
      name: "pcLoginStudent",
      component: () =>
        import("@/views/pc/login/pc-login-student.vue"),
    },
    //网页-学生登录-短信登录
    {
      path: "/pc-login-student-sms",
      name: "pcLoginStudentSms",
      component: () =>
        import("@/views/pc/login/pc-login-student-sms.vue"),
    },
    //网页-教师登录
    {
      path: "/pc-login-teacher",
      name: "pcLoginTeacher",
      component: () =>
        import("@/views/pc/login/pc-login-teacher.vue"),
    },
    //网页-书籍详情
    {
      path: "/pc-book-detail/:bookId",
      name: "pcBookDetail",
      component: () =>
        import("@/views/pc/book-detail/pc-book-detail.vue"),
      meta: {
        requireAuth: true,
        isPC: true
      },
    },
    //网页阅读器-学生
    {
      path: "/pc-reader",
      name: "pcReader",
      component: () =>
        import("@/views/pc/readers/pc-student-reader.vue"),
      meta: {
        requireAuth: true,
        isPC: true
      },
    },
    //网页阅读器-教师
    {
      path: "/pc-teacher-reader",
      name: "pcTeacherReader",
      component: () =>
        import("@/views/pc/readers/pc-teacher-reader.vue"),
      meta: {
        requireAuth: true,
        isPC: true
      },
    },
    //网页阅读器-首页
    {
      path: "/pc-reader-index",
      name: "pcReaderIndex",
      component: () =>
        import("@/views/pc/index/pc-index.vue"),
      meta: {
        requireAuth: true,
        keepAlive: true,
        isPC: true
      },
    },
    // pc-more-book-list
    {
      path: "/pc-more-books",
      name: "MoreBooks",
      component: () =>
        import("@/views/pc/more-books/pc-more-books.vue"),
      meta: {
        requireAuth: true,
        keepAlive: true,
        isPC: true
      },
    },
    {
      path: "/pc-teacher-modify-password",
      name: "tecModifyPsd",
      component: () =>
        import("@/views/pc/login/pc-teacher-modify-password.vue"),
      meta: {
        requireAuth: true,
        isPC: true
      },
    },
  ],
});