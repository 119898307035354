import axios from "axios";
import store from "@/store";
import { apiHost } from "./settings";

function createService(version = "v1") {
  const service = axios.create({
    baseURL: apiHost, // api 的 base_url
    timeout: 2000 // request timeout
  });

  // request interceptor
  service.interceptors.request.use(
    (config) => {
      config.baseURL += "/" + version;
      let jwt = store.state.auth.jwt;
      // Do something before request is sent
      if (jwt) {
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        config.headers["Authorization"] = jwt;
      }
      // post,put,patch的数据转换为formData类型数据
      const { method, data } = config;
      if (data && ["put", "patch", "post"].includes(method)) {
        // laravel兼容put,patch传文件
        if (["put", "patch"].includes(method)) {
          config.method = "post";
          config.data["_method"] = method;
        }
        // data转为formData
        const formData = new FormData();
        for (const key in data) {
          // formData.set(key, data[key]);
          formData.append(key, data[key]);
        }
        config.data = formData;
      }
      return config;
    },
    (error) => {
      // Do something with request error
      Promise.reject(error);
    }
  );

  // response interceptor
  if (version == "v1") {
    service.interceptors.response.use(
      (response) => {
        const { data: res } = response;
        if (res.code == 200) {
          // 非get请求的提示信息
          const { data } = res;
          if (res.meta !== null) {
            data.meta = res.meta;
          }
          return { data };
        } else {
          let { msg, code } = res;
          return Promise.reject({ msg, code });
        }
      },
      (error) => {
        const { code, message } = error;
        if (code == "ECONNABORTED") {
          //时间超时
          return Promise.reject({ code: "timeout", msg: "网络请求超时" });
        } else if (code == undefined && message == "Network Error") {
          //无网络处理
          return Promise.reject({
            code: "network",
            msg: "网络请求失败，\r\n请检查网络设置",
          });
        } else {
          return Promise.reject({ code, msg: message });
        }
      }
    );
  } else {
    service.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        const { data: res } = error.response;
        const { status: code } = error.response;
        return Promise.reject({ res, statusCode: code });
      }
    );
  }
  return service
}

//V1版本访问器
const service = createService();
//VN版本访问器
const proxy = new Proxy(service, {
  get(target, propKey) {
    if (/v\d+/.test(propKey)) {
      if (!target[propKey]) {
        target[propKey] = createService(propKey);
      }
      return target[propKey];
    } else {
      return target[propKey];
    }
  }
});

export default proxy;