export default {
  install: function (Vue) {
    const NativeApiClass =
      process.env.NODE_ENV === "production"
        ? require("./native-api.js").default
        : require("./native-api-debug.js").default;
    const nativeApi = new NativeApiClass();

    Vue.native = nativeApi;
    Vue.prototype.$native = nativeApi;
  },
};
