export default {
  namespaced: true,
  state: {
    //老师选择的班级ID
    classId: 0,
    //系统状态栏高度
    statusBarHeight: 0,
  },
  mutations: {
    setClassId(state, classId) {
      state.classId = classId;
    },
    setStatusBarHeight(state, statusBarHeight) {
      state.statusBarHeight = parseInt(statusBarHeight);
    },
  },
};
