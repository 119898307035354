//初始化接口
export function init() {
  return request.get("init");
}

//获取书本搜索列表
export function getBookSearchList() {
  return request.get("book/search/list");
}

//获取书本搜索列表
export function getBookSuggest(query) {
  // return request.get("book/search", { params: { query, res_type: "html" } });
  return request.get("book/search", { params: { query } });
}

/// 书架标签 GET
export function getBookTags() {
  return request.get("tags");
}

//获取书架书本列表
export function getBookList(isTeacher, per, page, tid, level) {
  var bookUrl = "books";
  if (isTeacher) {
    bookUrl = "teacher/books";
  }
  return request.get(bookUrl, { params: { per, page, tid, level } });
}

//获取书本详情
export function getBookDetailInfo(bid) {
  return request.get("book/" + bid);
}

//获取客观题列表
export function getBookObjectiveQues(bid) {
  return request.get("objects/" + bid);
}

//获取主观题列表
export function getBookSubjectiveQues(bid) {
  return request.get("subjects/" + bid);
}

// 教师获取未发布任务班级 GET
export function getUnpublishedClassesList(bookId) {
  return request.get("classes/unpublished", { params: { bookId } });
}

//教师发布任务
export function addTask(bid, classId, completeTime) {
  return request.post("/teacher/task", { bid, classId, completeTime });
}

//提交客观题答案
export function submitObjectAnswers(bid, objAns) {
  objAns = JSON.stringify(objAns);
  return request.post("/task/object/ending", { bid, objAns });
}

//提交主观题答案 单题提交
export function submitSubjectAnswers(subId, desc) {
  return request.post("/subject", { subId, desc });
}

//提交主观题答案 单题提交 lite ver.
export function submitLiteSubjectAnswers(data) {
  //data { subjectid, mediaId, desc }
  return request.post("/lite/subject/answers", data);
}

//修改主观题答案 单题 lite ver.
export function modifyLiteSubjectAnswers(subjectAnswerId, mediaId, desc) {
  return request.post(`/lite/subject/answers/${subjectAnswerId}`, {
    mediaId,
    desc,
  });
}

//主观题完成
export function completeSubjectQues(bid) {
  return request.post("/task/subject/ending", { bid });
}

export function getTaskBooks() {
  return request.post("/task/subject/ending", { bid });
}

export function getThirdPartTaskBooks() {
  return request.get("/thirdPart/tasks");
}

export function getThirdPartBooks(num, page) {
  return request.get("thirdPart/books", { params: { num, page } });
}

export function getShare(op, num, from) {
  return request.get("share", {
    params: {
      op,
      num,
      from,
    },
  });
}

export function deleteShare(id) {
  return request.delete("share/" + id);
}

export function likeShare(shareId) {
  return request.post("share/praise", { shareId });
}

export function postPlayCount(id, type) {
  return request.post("share/playRecord", { id, type });
}

//学生-修改密码
export function postStudentPassword(password, password_repeat) {
  return request.v2.post("student/password", { password, password_repeat });
}

//教师-整本书任务详情书本
export function getBookTaskDetailBook(id) {
  return request.v2.get("teacher/books/" + id);
}

//教师-整本书任务详情学生列表
export function getBookTaskDetailStudents(taskId) {
  return request.v2.get("teacher/progress/book-tasks/" + taskId);
}

//教师-诵读任务详情
export function getReciteTaskDetailChapters(unitId, classId) {
  return request.v2.get("teacher/progress/recite-tasks/" + unitId, {
    params: {
      classId,
    },
  });
}

//诵读任务详情在读/未读学生列表
export function getReciteTaskDetailStudents(chaptersId, classId) {
  return request.v2.get("teacher/recite-tasks/chapter-audios/" + chaptersId, {
    params: {
      classId,
    },
  });
}

//获取ISBN扫码书籍列表
export function getScanBooks(isbn) {
  return request.v2.get("student/offline-books", {
    params: {
      isbn,
    },
  });
}

//录入线下书籍
export function postScanBook(id) {
  return request.v2.post("student/offline-books", { id });
}

//学生-收藏列表
export function getStudentCollectBooks(studentId, limit, page) {
  return request.v2.get("student/book-collections", {
    params: {
      studentId,
      limit,
      page,
    },
  });
}

//获取学生线上阅读列表
export function getStudentOnlineBooks(studentId, limit, page) {
  return request.v2.get("student/online-books/read", {
    params: {
      studentId,
      limit,
      page,
    },
  });
}

//获取学生线下阅读列表
export function getStudentOfflineBooks(studentId, limit, cursor) {
  return request.v2.get("student/offline-books/read", {
    params: {
      studentId,
      limit,
      cursor,
    },
  });
}

//获取教师入口学生线上阅读列表
export function getTeacherOnlineBooks(studentId, limit, page) {
  return request.v2.get("teacher/online-books/read", {
    params: {
      studentId,
      limit,
      page,
    },
  });
}

//获取教师个人信息
export function getTeacherData() {
  return request.v1.get("teacher/data");
}

//获取班级列表
export function getTeacherClasses() {
  return request.v2.get("teacher/classes");
}

//班级ISBN书本数量
export function getClassOfflineBooksAmount(classId) {
  return request.v2.get(`teacher/class/${classId}/offline-books/scanned-amt`);
}

//班级任务数量
export function getClassTasksAmount(classId) {
  return request.v2.get(`teacher/class/${classId}/book-tasks/released-amt`);
}

//班级自读数量
export function getClassSelfReadAmount(classId) {
  return request.v2.get(`teacher/class/${classId}/book-tasks/not-released-amt`);
}

//获取教师入口学生线下阅读列表
export function getTeacherOfflineBooks(studentId, limit, cursor) {
  return request.v2.get("teacher/offline-books/read", {
    params: {
      studentId,
      limit,
      cursor,
    },
  });
}

//获取教师入口阅读任务数据
export function getTeacherReadingTaskData(classId, limit, cursor = 0) {
  return request.v2.get(
    "teacher/completeness/class/" + classId + "/book-tasks/released",
    {
      params: {
        limit,
        cursor,
      },
    }
  );
}

//修改教师入口阅读任务时间
export function putTeacherReadingTaskDate(taskId, completeTime) {
  return request.put("teacher/task/" + taskId, { completeTime });
}

//删除教师入口阅读任务
export function deleteTeacherReadingTask(taskId) {
  return request.delete("teacher/task/" + taskId);
}

//获取教师入口线下阅读数据
export function getTeacherOfflineReadingData(classId, limit, cursor) {
  return request.v2.get("teacher/class/" + classId + "/offline-books", {
    params: {
      limit,
      cursor,
    },
  });
}

//获取教师入口线下阅读弹窗数据
export function getTeacherOfflineReadingDialogData(classId, bookId) {
  return request.v2.get(
    "teacher/class/" + classId + "/offline-books/" + bookId
  );
}

//获取教师入口自发阅读数据
export function getTeacherSelfReadingData(classId, limit, cursor) {
  return request.v2.get(
    "teacher/completeness/class/" + classId + "/book-tasks/not-released",
    {
      params: {
        limit,
        cursor,
      },
    }
  );
}

//获取教师入口班级管理数据
export function getTeacherClassManageData(classId) {
  return request.get("teacher/classes/" + classId);
}

//删除教师入口班级管理的学生
export function deleteTeacherClassManageStudent(studentId) {
  return request.v2.delete("teacher/class/students/" + studentId);
}

export function getClassJwt(classId) {
  return request.v2.get(`teacher/class/${classId}/student-jwt`);
}

//获取线上书本任务完成状态列表
export function getStudentTaskRecordList(bookId, classId) {
  return request.v2.get(`teacher/class/${classId}/book-task-status/${bookId}`);
}

//获取线上书本任务完成汇总
export function getTaskSummaryInfo(bid, classId) {
  return request.get(`task/info`, { params: { bid, classId } });
}

//获取线上书本客观题数量
export function getTeacherObjectAmt(bookId) {
  return request.v2.get(`teacher/book/${bookId}/object-amt`);
}

//获取线上书本主观题数量
export function getTeacherSubjectAmt(bookId) {
  return request.v2.get(`teacher/book/${bookId}/subject-amt`);
}

//获取学生客观题答案列表
export function getObjectAnswers(studentId, ids) {
  return request.v2.get(
    `teacher/student/${studentId}/object-answers?ids=${ids}`
  );
}

//获取学生主观题答案列表
export function getSubjectAnswers(studentId, ids) {
  return request.v2.get(
    `teacher/student/${studentId}/subject-answers?ids=${ids}`
  );
}

//创建学生笔记
export function postStudentNote(bookId, epubCfi, markedText, note) {
  let params = markedText == "" ? { note } : { epubCfi, markedText, note }
  return request.v2.post("/student/book/" + bookId + "/notes", params)
}

//获取学生笔记列表
export function getStudentNotes(bookId) {
  return request.v2.get("/student/book/" + bookId + "/notes")
}

//删除学生笔记
export function deleteStudentNotes(bookId, noteId) {
  return request.v2.delete("/student/book/" + bookId + "/notes/" + noteId)
}

//修改学生笔记
export function updateStudentNotes(bookId, noteId, note) {
  return request.v2.put("/student/book/" + bookId + "/notes/" + noteId, { note })
}

//添加对老师的笔记评论的笔记
export function addStudentCommentNote(bookId, noteId, note) {
  return request.v2.post("/student/book/" + bookId + "/notes", { noteId, note })
}

//对老师的笔记评论的笔记列表
export function getStudentReaderComments(noteId) {
  return request.v2.get("/student/note/" + noteId + "/comments")
}

//对老师的笔记评论的笔记列表中的同学的笔记点赞
export function praiseStudentComment(noteId) {
  return request.v2.post("/student/book-note/" + noteId + "/praise")
}

//对老师的笔记评论的笔记列表
export function getTeacherReaderComments(noteId) {
  return request.v2.get("/teacher/note/" + noteId + "/comments")
}

//对老师的笔记评论的笔记列表中的同学的笔记点赞
export function teacherPraiseStudentComment(noteId) {
  return request.v2.post("/teacher/book-note/" + noteId + "/praise")
}

//创建教师笔记
export function postTeacherNote(bookId, epubCfi, markedText, note) {
  let params = markedText == "" ? { note } : { epubCfi, markedText, note }
  return request.v2.post("/teacher/book/" + bookId + "/notes", params)
}

//教师获取笔记列表
export function getTeacherNotes(bookId) {
  return request.v2.get("/teacher/book/" + bookId + "/notes")
}

//删除教师笔记
export function deleteTeacherNotes(bookId, noteId) {
  return request.v2.delete("/teacher/book/" + bookId + "/notes/" + noteId)
}

//修改教师笔记
export function updateTeacherNotes(bookId, noteId, note) {
  return request.v2.put("/teacher/book/" + bookId + "/notes/" + noteId, { note })
}

//阅读器老师说
export function getEPubTeacherSay(bookId) {
  return request.v2.get(
    `student/book/${bookId}/teacher-notes`
  );
}

//阅读器老师说-学生笔记
export function getEPubTeacherSayStudentNotes(noteId) {
  return request.v2.get(
    `student/teacher-notes/${noteId}/reply`
  );
}

//提交阅读器上次阅读信息
export function postEPubLastReadInfo(bid, chapters, wordNum, startTime, endTime, lastReadCfi, progress) {
  return request.post("/book/time", { bid, chapters, wordNum, startTime, endTime, lastReadCfi, progress })
}

//阅读器-提交学生配置
export function postEPubStudentReadConfig(epubTheme, epubFontSize) {
  return request.v2.post("/student/reader-configs", { epubTheme, epubFontSize })
}

//阅读器-提交老师配置
export function postEPubTeacherReadConfig(epubTheme, epubFontSize) {
  return request.v2.post("/teacher/reader-configs", { epubTheme, epubFontSize })
}

//阅读器-获取学生配置
export function getEPubStudentReadConfig() {
  return request.v2.get("/student/reader-configs")
}

//阅读器-获取老师配置
export function getEPubTeacherReadConfig() {
  return request.v2.get("/teacher/reader-configs")
}

//学生对教师批注的回复笔记列表
export function getStudentNoteComments(noteId) {
  return request.v2.get("student/note/" + noteId + "/comments")
}