import MobileDetect from "mobile-detect";
const mobileOS = new MobileDetect(window.navigator.userAgent).os();

const ios = {
  //set up ios webview bridge
  setupWebViewJavascriptBridge: function(callback) {
    const WebViewJavascriptBridge = window.WebViewJavascriptBridge;

    if (WebViewJavascriptBridge) {
      return callback(WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    const WVJBIframe = document.createElement("iframe");
    WVJBIframe.style.display = "none";
    WVJBIframe.src = "https://__bridge_loaded__";
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function() {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
  },
};

//android tools
const android = {
  call: function(name, params, callback) {
    let WebViewJavascriptBridge = window.WebViewJavascriptBridge;

    if (WebViewJavascriptBridge) {
      WebViewJavascriptBridge.callHandler(name, params, callback);
    } else {
      document.addEventListener(
        "WebViewJavascriptBridgeReady",
        function() {
          //重新获取Bridge
          WebViewJavascriptBridge = window.WebViewJavascriptBridge;

          WebViewJavascriptBridge.callHandler(name, params, callback);

          document.removeEventListener(
            "WebViewJavascriptBridgeReady",
            this,
            false
          );
        },
        false
      );
    }
  },

  //android register
  register: function(name, handle) {
    let WebViewJavascriptBridge = window.WebViewJavascriptBridge;

    if (WebViewJavascriptBridge) {
      WebViewJavascriptBridge.registerHandler(name, handle);
    } else {
      document.addEventListener(
        "WebViewJavascriptBridgeReady",
        function() {
          //重新获取bridge
          WebViewJavascriptBridge = window.WebViewJavascriptBridge;

          WebViewJavascriptBridge.registerHandler(name, handle);

          document.removeEventListener(
            "WebViewJavascriptBridgeReady",
            this,
            false
          );
        },
        false
      );
    }
  },
};

/**
 * 注册原生回调
 * @name String 接口名
 * @return Promise
 **/
function register(name) {
  return new Promise((resolve) => {
    if (mobileOS == "iOS") {
      ios.setupWebViewJavascriptBridge(function(bridge) {
        bridge.registerHandler(name, (data) => resolve(data));
      });
    } else {
      android.register(
        name,
        androidCallback((data) => resolve(data))
      );
    }
  });
}

/**
 * 调用原生接口
 * @name String 接口名
 * @params Object 接口参数
 * @return Promise
 **/
function call(name, params) {
  return new Promise((resolve) => {
    if (mobileOS == "iOS") {
      ios.setupWebViewJavascriptBridge(function(bridge) {
        bridge.callHandler(name, params, (data) => resolve(data));
      });
    } else {
      android.call(
        name,
        params,
        androidCallback((data) => resolve(data))
      );
    }
  });
}

//安卓回调函数，将JSON字符串转换为对象bridge
function androidCallback(callback) {
  return function(res) {
    try {
      //JSON字符串转为对象
      //"ture","false","数字"转为true,false,数字
      res = JSON.parse(res);
    } catch (e) {
      console.log("not json string");
    }
    callback.call(null, res);
  };
}

// return {
//   /**
//    * 调用JAVA接口
//    * @name 接口名
//    * @params 接口参数
//    * @callback 回调函数
//    **/
//   call: function() {
//     return call.apply(null, arguments);
//   },
//   /**
//    * 注册JAVASCRIPT接口
//    * @name 接口名
//    * @callback 被调用接口回调
//    **/
//   register: function() {
//     return register.apply(null, arguments);
//   },
// };

export { call, register };
