const mode = process.env.NODE_ENV;
const appHost = process.env.VUE_APP_APP_HOST;

const apiHost = `${appHost}/api`;

const sentryEnable = parseInt(process.env.VUE_APP_SENTRY_ENABLE);

const resHost = process.env.VUE_APP_RES_HOST;

const wxHost = process.env.VUE_APP_WX_HOST;

const dataPointHost = process.env.VUE_APP_DATA_POINT_HOST;

const frontHost = process.env.VUE_APP_FRONT_HOST;

export { appHost, apiHost, resHost, sentryEnable, wxHost, dataPointHost, frontHost };