<template>
  <div
    class="c-loading-circle d-flex align-center justify-center"
    :style="circleStyle"
  >
    <div
      class="c-loading-circle-snipper loading-spiral"
      :style="circleSnipperStyle"
    ></div>
  </div>
</template>

<script>
export default {
  name: "c-loading-circle",
  props: {
    bgWidth: {
      type: [String, Number],
      default: "100%",
    },
    bgHeight: {
      type: [String, Number],
      default: "100%",
    },
    bgColor: {
      type: String,
      default: "#FFF",
    },
    snipperColor: {
      type: String,
      default: "",
    },
    snipperSize: {
      type: [Number, String],
      default: 30,
    },
  },
  computed: {
    circleStyle() {
      const { bgWidth, bgHeight, bgColor } = this;
      const style = {};
      Object.assign(style, {
        width: isNaN(bgWidth) ? bgWidth : `${bgWidth}px`,
      });
      Object.assign(style, {
        height: isNaN(bgHeight) ? bgHeight : `${bgHeight}px`,
      });
      Object.assign(style, {
        "background-color": bgColor,
      });

      return style;
    },
    circleSnipperStyle() {
      let { snipperSize, snipperColor, $vuetify: vuetify } = this;
      snipperSize = isNaN(snipperSize) ? snipperSize : `${snipperSize}px`;
      const style = {};

      Object.assign(style, {
        width: snipperSize,
        height: snipperSize,
      });

      Object.assign(style, {
        "border-color": snipperColor || vuetify.theme.defaults.light.minor,
        "border-right-color": "transparent",
        "border-radius": `${parseInt(snipperSize) / 2}px`,
      });
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-loading-circle-snipper {
  border: {
    width: 2px;
    style: solid;
  }
  animation: loading-rotating linear 0.85s infinite;
}

@keyframes loading-rotating {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
