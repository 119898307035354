import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

const themeColors = {
  primary: "#5CB58F",
  pressed: "#4D9979",
  common: "#444444",
  minor: "#999999",
  disable: "#8DCBB0",
  info: "#CACACA",
  accent: "#4D9F7C",
  error: "#FB5A5A",
  divider: "#F0F0F0",
  body: "#666666",
  backF8: "#F8F8F8",
  blueGray: "#707F8D"
};

export { themeColors };

export default new Vuetify({
  theme: {
    themes: {
      light: themeColors,
    },
    options: {
      customProperties: true,
    },
  },
});