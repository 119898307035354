<template>
  <c-loading-circle
    class="global-loading"
    v-if="visible"
    bg-color="rgba(0,0,0,.5)"
    snipper-color="#FFF"
  ></c-loading-circle>
</template>

<script>
import CLoadingCircle from "@/components/c-loading-circle";
import { mapState } from "vuex";
export default {
  name: "global-loading",
  computed: {
    ...mapState({
      loading: (v) => v.utilsLoading,
    }),
    visible() {
      return this.loading.visible;
    },
  },

  components: {
    CLoadingCircle,
  },
};
</script>

<style lang="scss" scoped>
.global-loading {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
</style>
