import { getTeacherClasses } from "@/api/library.js";
export default {
  namespaced: true,
  state: {
    classList: null,
  },
  mutations: {
    saveClassList(state, classList) {
      state.classList = classList;
    },
  },
  actions: {
    getClassList({ commit, state }) {
      let { classList } = state;
      if (classList) {
        return new Promise(function (resolve) {
          resolve(classList);
        });
      } else {
        return new Promise(function (resolve) {
          getTeacherClasses()
            .then(({ classes: classList }) => {
              commit("saveClassList", classList);
              resolve(classList);
            })
            .catch(({ res, statusCode }) => { });
        });
      }
    },
  },
};