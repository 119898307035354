import { call, register } from "./bridge";

class NativeApi {
  /**
   * 关闭
   */
  close() {
    return call("close");
  }

  //打开原生
  openNative(name, params = null) {
    const variables = { name };
    if (params) Object.assign(variables, { params });
    return call("openNative", variables);
  }

  /**
   * 打开原生书本详情
   * @param {*} bookId
   * @param {*} isOffline
   * @param {*} taskId
   * @param {*} read
   * @param {*} recentDay
   */
  openNativeBookDetail(
    bookId,
    isOffline = false,
    taskId = 0,
    read = 0,
    recentDay = 0
  ) {
    return this.openNative("book-detail", {
      bookId,
      isOffline,
      taskId,
      read,
      recentDay,
    });
  }

  /**
   * 打开原生班级任务
   * @param {*} classId
   * @param {*} bookId
   * @param {*} bookName
   */
  openNativeClassTask(classId, bookId, bookName) {
    return this.openNative("class-task", {
      classId,
      bookId,
      bookName,
    });
  }

  /**
   * 打开原生班级任务汇总详情
   * @param {*} bookId
   */
  openNativeTaskTotalResult(bookId, classId) {
    return this.openNative("task-total-result", {
      bookId,
      classId,
    });
  }

  openTeacherTaskManager() {
    return this.openNative("teacher-task-manager");
  }

  /**
   * 打开老师个人信息页
   */
  openNativeTeacherPerson() {
    return this.openNative("teacher-person");
  }

  /**
   * 获取JWT
   */
  getToken() {
    return call("getToken");
  }

  /**
   * 登录
   * @param {String} jwt
   */
  login(jwt) {
    return call("login", { jwt });
  }

  /**
   * 埋点
   * @param {String} category 事件分类
   * @param {String} name 事件名
   * @param {String=""} value 事件主值
   * @param {Object={}} params 事件其它参数
   */
  track({ category, name, value = "", params = {} }) {
    return call("track", {
      category,
      name,
      value: value?.toString() ?? value,
      params: JSON.stringify(params),
    });
  }

  onready() {
    return register("init");
  }

  /**
   * 获取用户信息
   * @return {Promise({name: "用户名称"})}
   *
   */
  getUser() {
    return call("getUser");
  }

  onback() {
    return register("back");
  }

  //统一身份认证用户绑定成功回调
  bind() {
    return call("bind");
  }

  //获取状态栏的高度
  getStatusBarHeight() {
    return call("getStatusBarHeight");
  }

  //获取班级id
  getClassId() {
    return call("getClassId");
  }

  //退出账号
  logOut() {
    return call("logOut");
  }

  //扫描
  scan() {
    return call("scan");
  }

  wechatShare({ shareTo, title, description, webpageUrl, thumbImage }) {
    return call("wechatShare", {
      shareTo,
      title,
      description,
      webpageUrl,
      thumbImage,
    });
  }

  //老师模拟（学生）登录
  teacherMockLogin(jwt) {
    return call("teacherMockLogin", { jwt });
  }

  closeAtLastPage(bookId) {
    return call("closeAtLastPage", bookId);
  }

  copy(str) {
    return call("copy", { str });
  }
}

export default NativeApi;