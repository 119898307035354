import axios from "axios";
import { v4 as uuidv4 } from "uuid";

function log(data) {
  console.info(data);
}

//提交数据到行为日志
function post(url, data) {
  data = JSON.stringify([data]);
  if (url) {
    axios.post(
      url,
      data,
      { headers: { "Content-Type": "application/json" } }
    );
  }
}

//生成唯一码
function genUUID() {
  return uuidv4();
}

export { log, post, genUUID };