import { getTeacherData } from "@/api/library.js";
export default {
  namespaced: true,
  state: {
    account: "",
    headImg: "",
    meta: "",
    name: "",
    schoolId: "",
    schoolName: "",
    roles: [],
  },
  mutations: {
    saveTeacherData(state, teacherData) {
      Object.assign(state, teacherData);
    },
  },
  actions: {
    getTeacherInfo({ commit, state }) {
      if (state.account) {
        console.log('state', state)
        return new Promise(function (resolve) {
          resolve(Object.assign({}, state));
        });
      } else {
        return new Promise(function (resolve) {
          getTeacherData().then(({ data: teacherData }) => {
            commit("saveTeacherData", teacherData);
            resolve(teacherData);
          });
        });
      }
    },
  },
  getters: {
    //班主任标志
    isClassTeacher(state) {
      return state?.roles?.includes("teacher") ?? true;
    },
  },
};
