import { getUserData } from "@/api/api-pc.js";
export default {
  namespaced: true,
  state: {
    id: 0,
    headImg: "",
    name: "",
    schoolName: "",
    classInfo: "",
    classNumber: "",
    nameLastModifyAt: "",
    canChangeName: 1,
    phoneNumber: 0,
    mobile: "",
    //老师
    school: {},
    account: "",
    class: {

    },

  },
  mutations: {
    saveUserData(state, userData) {
      Object.assign(state, userData);
    },
  },
  actions: {
    getUserInfo({ commit, state }) {
      if (state.id) {
        return new Promise(function (resolve) {
          resolve(Object.assign({}, state));
        });
      } else {
        return new Promise(function (resolve) {
          getUserData().then(({ data: userData }) => {
            commit("saveUserData", userData);
            resolve(userData);
          });
        });
      }
    },
    clearUserInfo({ commit }) {
      let noState = { id: 0, account: "" }
      //account 判断老师还是学生
      //id 是否从接口拿数据
      commit("saveUserData", noState);
    },
  },
  getters: {

  },
};
