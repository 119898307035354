<template>
  <div>
    <global-msg-box></global-msg-box>
    <global-snackbar></global-snackbar>
    <global-loading></global-loading>
  </div>
</template>

<script>
import GlobalMsgBox from "./global-msg-box";
import GlobalSnackbar from "./global-snackbar";
import GlobalLoading from "./global-loading";

export default {
  name: "global-popups",
  components: {
    GlobalMsgBox,
    GlobalSnackbar,
    GlobalLoading,
  },
};
</script>
