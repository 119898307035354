class VuexUtilClass {
  //vuex对象
  vuexObject = {};
  //vuex组件state参数
  defaultParams = {};
  //控制可见的字段
  visibleParamName = "visible";

  constructor(_defaultParams) {
    const { vuexObject, defaultParams, visibleParamName } = this;

    Object.assign(defaultParams, _defaultParams, { [visibleParamName]: false });

    //设置vuex state
    Object.assign(vuexObject, {
      namespaced: true,
      state: Object.assign({}, defaultParams),
      mutations: {
        //显示组件
        open(state, params = {}) {
          Object.assign(state, params, { [visibleParamName]: true });
        },
        //隐藏组件
        close(state) {
          Object.assign(state, defaultParams);
        },
      },
    });
  }
}

export default VuexUtilClass;
